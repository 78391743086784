
export default {
  methods: {
    showCookieSettings() {
      if (window.klaro) {
        window.klaro.show(undefined, true)
      }
    },
  },
}
