import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=d9a92da8&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Decor: require('/vercel/path0/components/Decor.vue').default,HeadlineLabel: require('/vercel/path0/components/HeadlineLabel.vue').default,Headline: require('/vercel/path0/components/Headline.vue').default,Contact: require('/vercel/path0/components/Contact.vue').default,Container: require('/vercel/path0/components/Container.vue').default,Icon: require('/vercel/path0/components/Icon.vue').default,Footer: require('/vercel/path0/components/Footer.vue').default})
